import React, { useEffect, useState } from "react";
// import ViewDumpsterPricesForm from "./viewDumpsterPricesForm";
import QuoteRequestModalLibrary from "./quote-request-modal-library";

const SidebarForm = ({ code, site, section }) => {
    const commercialProjectType = "509";
    const [fixed, setFixed] = useState(false);

    useEffect(() => {
        if(section !== 'blogs') {
            const component = document.getElementById("fixed-btns")?.offsetTop;
            window.addEventListener('scroll', () => handleScroll((component)));
            return () => window.removeEventListener('scroll', () => handleScroll(component));
        }
    }, [])

    const handleScroll = (componentPosition) => {
        if (window.innerWidth < 992 && componentPosition) {
            if (window.scrollY >= (componentPosition + 80)) {
                setFixed(true);
            } else {
                setFixed(false);
            }
        } else {
            // Reset state if screen width exceeds 992px
            setFixed(false);
        }
    }

    const getFormByCode = () => {
        let title = '';
        let subtitle = '';
        let messages = [];
        let ondeffCode = '';
        let initialFormData;
        let btnLabel = 'Get matched with pros';
        let bottomLabel = 'Share project details to get started'
        let iconUrl = '/images/icons/orange-check.svg'
        if (site === 'dumpster') {
            switch(code) {
                case 'junk-removal':
                    title = 'Make Junk Disappear the Easy Way';
                    subtitle = 'Get free quotes from local junk removal pros.'
                    messages = [
                        {
                            icon: iconUrl,
                            label: 'Fast, full-service removal & cleanouts'
                        },
                        {
                            icon: iconUrl,
                            label: 'Friendly professionals do all the heavy lifting'
                        }
                    ]
                    ondeffCode = 'junkRemovalQuoteRequest';
                    btnLabel = 'Get started';
                    break;
                case 'comercial-trash-service': 
                    title = 'Get Commercial Waste Solutions the Easy Way';
                    subtitle = 'Compare up to 3 quotes for your commercial property'
                    messages = [
                        {
                            icon: iconUrl,
                            label: 'Describe what you need'
                        },
                        {
                            icon: iconUrl,
                            label: 'Get matched to commercial waste pros'
                        },
                        {
                            icon: iconUrl,
                            label: 'Compare quotes & hire when ready'
                        }
                    ]
                    ondeffCode = 'commercialWasteQuoteRequest';
                    initialFormData = [{ key: "project_type", value: commercialProjectType }]
                    break
                default:
                    title = 'Need a Dumpster Soon?';
                    subtitle = 'Skip the hassle. Book a dumpster the easy way.'
                    messages = [
                        {
                            icon: iconUrl,
                            label: 'See who is available'
                        },
                        {
                            icon: iconUrl,
                            label: 'Compare prices'
                        },
                        {
                            icon: iconUrl,
                            label: 'Book online'
                        }
                    ]
                    ondeffCode = 'priceAndAvailabilityForm';
                    btnLabel = 'Get pricing & availability';
                    bottomLabel = 'Book available dumpsters the easy way';
                    break;
            }
        } else {
            title = 'Remove Anything the Easy Way';
            subtitle = 'Get free quotes from local demolition contractors'
            messages = [
                {
                    icon: iconUrl,
                    label: 'Describe your project'
                },
                {
                    icon: iconUrl,
                    label: 'Get matched to local pros'
                },
                {
                    icon: iconUrl,
                    label: 'Compare quotes & hire the right pro'
                }
            ]
            ondeffCode = 'demolitionQuoteRequest'
        }
        
        return (
            <div className="sidebar-container">
                <h3 className="sidebar-title">{title}</h3>
                <h4 className="sidebar-subtitle">{subtitle}</h4>
                <div className="sidebar-messages-container">
                    {messages?.length > 0 && messages.map((message, index) => (
                        <div key={index} className="message-container">
                            <img src={message.icon} alt={`message${index}`} loading="lazy" className="sidebar-icon"/>
                            <p className="sidebar-message">{message.label}</p>
                        </div>
                    ))}
                </div>
                {ondeffCode && 
                <div id="fixed-btns" className={`sidebar-call-to-action ${fixed ? 'fixed-btn' : ''}`} >
                    <div className="sidebar-call-to-action-container">
                        <QuoteRequestModalLibrary
                            startVisible={false}
                            ondeffCode={ondeffCode}
                            btnLabel={btnLabel}
                            class="get-a-quote"
                            initialFormData={initialFormData}
                        />
                        <p className="sidebar-bottom-message">{bottomLabel}</p>
                    </div>
                </div>
                }
            </div>
        )
    }
  
    return (
        getFormByCode(code)
    )
}

export default SidebarForm;
