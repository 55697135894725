import React, { useRef } from 'react';
import OndeffModal from './OndeffModal';

const QuoteRequestModalLibrary = (props) => {
    const btnLabel = props.btnLabel || 'Get a quote'
    const childRef = useRef();

    const handleClick = () => {
        if (childRef.current) {
          childRef.current.triggerChangeShowModal();
        }
    };

    return (
        <>
            <button style={{ cursor: 'pointer' }} className={props.class} onClick={handleClick}>{btnLabel}</button>
            <OndeffModal
                {...props}
                ref={childRef}
            />
        </>
    )
}

export default QuoteRequestModalLibrary;