import React, { lazy, Suspense, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import Modal from 'react-modal';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import CloseModalConfirmation from './closeModalConfirmation';
const OnDeffImplementation = lazy(() => import('./on-deff-implementation'));

const OndeffModal = forwardRef((props, ref) => {
    const proId = props.proid;
    const [title, setTitle] = useState(props.title)
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(props.startVisible)
    const [closeModal, setCloseModal] = useState(false);
    const childRef = useRef();

    const changeShowModal = () => {
        if(!showModal) {
            setShowModal(true);
            setTitle(props.title);
        } else {
            const submitMessageElement = document.querySelector('.submitMessageGA');
            if(props.ondeffCode !== 'reviewForm' && !submitMessageElement) {
                setCloseModal(true);
            } else {
                setShowModal(false);
            }
        }
    }

    const handleCloseModal = () => {
        if(props.ondeffCode === 'priceAndAvailabilityForm') {
            if (childRef?.current) {
                const formValues = childRef.current?.getCurrentValues();
                if(formValues?.odf_place_id && formValues?.wasteType) {
                    setTimeout(() => {
                        closeForm();
                        window.location.href = `/book?a=${formValues.odf_place_id}&w=${formValues.wasteType}`
                    }, 5000);
                } else {
                    closeForm();
                }
            } else {
                closeForm();
            }
        } else {
            closeForm();
        }
        if(props.handleCallback) {
            props.handleCallback();
        }
    }

    const closeForm = () => {
        setShowModal(false);
        setCloseModal(false);
    }

    const handleContinueForm = () => {
        setCloseModal(false);
    }

    useImperativeHandle(ref, () => ({
        triggerChangeShowModal: changeShowModal
    }))

    let initialValuePoolLimit = 0;
    if (typeof window !== 'undefined') {
        const params = new URLSearchParams(window.location.search)
        if (params.get("test") == "true") {
            initialValuePoolLimit = 1;
        }
    }
    const [limitPoolSize, setPoolTest] = useState(initialValuePoolLimit)

    const [modalStyles, setModalStyles] = useState(
        {
            content:
            {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                borderRadius: '10px',
                border: 'none',
                backgroundColor: '#FFFFFF',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                padding: '0px',
                minHeight: 'auto',
                width: '600px',
            }
        })

    const getInitialData = () => {
        if (props.initialFormData && props.initialFormData.length > 0) {
            return [
                ...props.initialFormData,
                { key: 'limit_poolsize', value: limitPoolSize },
            ]
        }
        else {
            if(proId) {
                return [
                    { key: 'pro_id', value: proId },
                    { key: 'limit_poolsize', value: limitPoolSize }
                ]
            } else {
                return [
                    { key: 'limit_poolsize', value: limitPoolSize }
                ]
            }
            
        }
    }

    const handleCallBackAfterSubmit = (formData) => {
        if (props.callBackAfterSubmit) {
            props.callBackAfterSubmit(formData)
        }
        if (props.ondeffCode === 'reviewForm'){
            setTitle('')
        }
        else if (props.ondeffCode === 'priceAndAvailabilityForm' || props.ondeffCode === 'availabilityForm') {
            let newFormData = formData
            if (typeof formData === 'string') {
                try {
                    newFormData = JSON.parse(formData);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            }
            setTitle(newFormData.isPoolProviderEmpty ? 'No matching pros found' : `We're finding available pros now`);
        }
    }

    const handleCallBackErrorAfterSubmit = (formData) => {
        if (props.callBackErrorAfterSubmit) {
            props.callBackErrorAfterSubmit(formData)
        }
        if (props.ondeffCode === 'reviewForm'){
            setTitle('')
        }
    }

    return (
        <Modal
            id={1}
            isOpen={showModal}
            style={modalStyles}
            contentLabel={props.ondeffCode != 'reviewForm' ? 'Get a Quote!' : 'Write a Review'}
            shouldCloseOnOverlayClick={false}
            preventScroll={true}
            ariaHideApp={false}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                className={loading && 'ondeffBackdrop'}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <>
                <div className={closeModal ? 'display-none-form' : (!title ? "modal-header" : "modal-header review-quote-step-modal")} >
                    {title && 
                    <h2>{title}</h2>
                    }
                    <button type="button" className="close closermodal" onClick={changeShowModal} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div id="quote-steps-modal-library" className={closeModal ? 'display-none-form' : ''}>
                    <Suspense fallback={<h1>Loading…</h1>}>
                        <OnDeffImplementation
                            initialData={getInitialData()}
                            formCode={props.ondeffCode}
                            loadingFunction={setLoading}
                            handleCallBackAfterSubmit={handleCallBackAfterSubmit}
                            handleCallBackErrorAfterSubmit={handleCallBackErrorAfterSubmit}
                            ref={childRef}
                        >
                        </OnDeffImplementation>
                    </Suspense>
                </div>
                <div id="temp" className={closeModal ? 'display-none-form' : ''}></div>
            </>
            {closeModal &&
            <CloseModalConfirmation
                handleContinueForm={handleContinueForm}
                handleCloseModal={handleCloseModal}
            />
            }
        </Modal>
    )
})

export default OndeffModal;