import React, { useRef } from 'react';
import OndeffModal from './OndeffModal';

const CallToActionDescription = (props) => {
  const btnLabel = props.btnLabel || 'Get a quote'
  const childRef = useRef();

  const handleClick = (e) => {
    if (childRef.current) {
      e.stopPropagation();
      childRef.current.triggerChangeShowModal();
    }
  };
  
  return (
    <>
      <div className="flex-footer" onClick={handleClick}>
        <div class='call-to-action-info'>
          <span>+</span>
          <p>{props.detail}</p>
        </div>
        <button style={{ cursor: 'pointer' }} className={props.class} >{btnLabel}</button>
      </div>
      <OndeffModal
        {...props}
        ref={childRef}
      />
    </>
  )
}

export default CallToActionDescription;
