import React, { useState, useEffect } from "react"
import OndeffModal from "./OndeffModal"
import { formatBookingJson, manualGoogleEvents } from "../helpers/helpers"

export default function HeaderMenuDumpster({ isMenuOpen, toggleMenu }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [openBookModal, setOpenBookModal] = useState(false)

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState)
  }

  const handleCallback = formData => {
    formatBookingJson(formData)
    manualGoogleEvents("manualFormSubmition")
    window.location.href = "/book"
  }

  return (
    <div
      className="collapse navbar-collapse justify-content-end"
      id="navbarSupportedContent"
    >
      <div className={`mobile-content ${isMenuOpen ? "show" : ""}`}>
        <div className="mobile-header">
          <button
            type="button"
            className="modal-closer"
            onClick={toggleMenu}
            aria-label="Close"
          >
            <span>X</span>
          </button>
        </div>
        <ul className="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="#" onClick={() => setOpenBookModal(true)}>
              Book a Dumpster
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/dumpster-rental">
              Find Dumpster Services
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/junk-removal">
              Find Junk Removal Services
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/commercial-waste-management">
              Find Commercial Waste Services
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/blog">
              Waste Removal Tips & Advice
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/pro/guide/dumpster-junk-leads">
              Join as a Pro
            </a>
          </li>
        </ul>
      </div>

      {!isMenuOpen && (
        <ul className="navbar-nav desktop">
          <li class="nav-item dropdown">
            <a
              id="hm_mainopt"
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              aria-expanded={isDropdownOpen}
              onClick={toggleDropdown}
            >
              Find Pros
            </a>
            <ul
              id="dropdownHeader"
              className={`dropdown-menu dropdown-menu-right ${
                isDropdownOpen ? "show" : ""
              }`}
              aria-labelledby="hm_mainopt"
            >
              <li>
                <a class="dropdown-item" href="/dumpster-rental">
                  Dumpster Rental
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/junk-removal">
                  Junk Removal
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/commercial-waste-management">
                  Commercial Waste
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/blog">
              Tips & Advice
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/pro/guide/dumpster-junk-leads">
              Join as a Pro
            </a>
          </li>
        </ul>
      )}

      {openBookModal && (
        <OndeffModal
          startVisible={true}
          class="get-a-quote view-prices"
          ondeffCode="viewDumpsterPricesForm"
          handleCallback={() => setOpenBookModal(false)}
          callBackAfterSubmit={handleCallback}
        />
      )}
    </div>
  )
}
