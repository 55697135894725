const convertHTMLObjToArrayForKpp = (htmlObj) => {
  return Object.entries(htmlObj).map(([key, html]) => {
    return { id: key, ...parseHTMLStringForKpp(html) };
  });
}

const parseHTMLStringForKpp = (htmlString) => {
  const name = (htmlString.match(/<[^>]+data-id="name"[^>]*>([^<]+)<\/[^>]+>/) || [])[1] ?? '';
  const description = (htmlString.match(/<[^>]+data-id="description"[^>]*>\s*([^<]+)/s) || [])[1]?.trim() ?? '';
  const rating = (htmlString.match(/<[^>]+data-id="ratingValue"[^>]*>([^<]+)<\/[^>]+>/) || [])[1] ?? '';
  const reviewCount = (htmlString.match(/<[^>]+data-id="reviewCount"[^>]*>([^<]+)<\/[^>]+>/) || [])[1] ?? '';
  const address = (htmlString.match(/<[^>]+data-id="address"[^>]*>([^<]+)<\/[^>]+>/) || [])[1] ?? '';
  const image = (htmlString.match(/<[^>]+srcset="([^"]+)"[^>]*>/) || [])[1] ?? '';

  return { name, description, rating, reviewCount, address, image };						
}

const generateJsonLdForKpp = (kppData, site, placeName = '') => {
  return kppData.map(item => {
    const aggregateRating = item.rating ? {
      "@type": "AggregateRating",
      ratingValue: item.rating,
      reviewCount: item.reviewCount
    } : undefined;

    const address = {
      "@type": "PostalAddress",
      addressLocality: item.address || placeName,
      addressCountry: "US"
    };

    const logo = item.image ? {
      "@type": "ImageObject",
      contentUrl: item.image
    } : undefined;
    
    return {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      additionalType: site === 'demolition' ? "https://schema.org/HomeAndConstructionBusiness" : "https://schema.org/WasteManagement",
      name: item.name,
      description: item.description,
      ...(aggregateRating && { aggregateRating }),
      address,
      ...(logo && { logo })
    };
  });
};

const formatBookingJson = (data) => {
  let newFormData = data
  if (typeof data === 'string') {
    try {
      newFormData = JSON.parse(data);
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  }
  const initialValues = [
    { key: 'wasteType', value: [+newFormData.wasteType] },
    { key: 'deliveryAddress', value: (newFormData.odf_street && newFormData.odf_street2) ? newFormData.odf_street + ', ' + newFormData.odf_street2 : ''},
    { key: 'fullFormData', value: newFormData},
  ]
  localStorage.setItem('onlineBookingData',JSON.stringify({ placeId: null, baseServiceId: "62a9f2cd61706a135071a7e3", skip: 0, limit: 21, initialValues: initialValues, finalDataForBooking: null }))
  return newFormData;
}

const manualGoogleEvents = (eventName) => {
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer.push({
      event: eventName
    });
  }
}

module.exports = { convertHTMLObjToArrayForKpp, generateJsonLdForKpp, formatBookingJson, manualGoogleEvents }