import React from "react";

export default class KppTips extends React.Component  
{
    constructor(props)    
    {
        super(props)      

        this.hide = false
        this.text = ''   

        if(this.props.tip.body !== undefined) this.text = this.props.tip.body       
        if(this.text === '') this.hide = true        
    }

    getTitle = () =>
    {
        let title = `{Keyword} Tips for ${this.props.place.name}`

        this.props.codes.map(code =>
        {                   
            if(code.code === this.props.code)
            {
                let codename = ''

                switch(code.name)
                {
                    case 'Demolition Contractors':
                        codename = 'Demolition'
                        break
                    
                    case 'Roll Off - Dumpsters for Rent':
                        codename = 'Roll Off Dumpster'
                        break
                    
                    default:
                        codename = code.name
                        break
                }
                
                title = title.replace('{Keyword}', codename) 
            }            
        })
        
        return title
    }

    getTxt = (text) =>
    {
        let txt = text        
        txt = txt.replace(/\r?\n|\r/g, "")
        txt = txt.replace(/<table/g, "<div style='overflow:auto;'><table")    
        txt = txt.replace(/\/table>/g, "\/table></div>") 
        return txt
    }

    render()
    {
        if(this.hide)
        {
            return <></>
        }
        
        return (
            <div className="kpptips blog">     
                <h2 className="main">{this.getTitle()}</h2>  
                <div class="post single-item">   
                    {/* <h3 className="title">{this.props.tip.name}</h3>              */}
                    <div dangerouslySetInnerHTML={{__html: this.getTxt(this.text)}}></div>
                </div>              
            </div>
        )
    }
};