import React from "react";

export default class PopularArticlesKpp extends React.Component  
{
    constructor(props)    
    {
        super(props);

        this.articles = this.props.articles;


    }
    
    render()
    {
        return (
            <div className="popular-articles-kpp">
                <h2>Popular Articles</h2>            
                <div className="provider-card">    
                    <div className="items-list">
                    {
                        this.articles.map((article, index) =>
                        (
                            <a href={article.url} className="item">
                                <div className="item-text">
                                    <h6>{article.title}</h6>
                                </div>
                            </a>
                        ))
                    }
                    </div> 
                </div>
            </div>
        )
    }
};