import React from "react";

export default class RelatedServices extends React.Component  
{
    constructor(props)    
    {
        super(props);        
        
        const code = this.props.code;
        const place = this.props.place !== undefined ? this.props.place.name : '';        
        const place_name = place.replace(/ /g,'-').replace(/,/g,'').replace(/---/g,'-').toLowerCase(); 
        const place_type = this.props.place !== undefined ? this.props.place.ht : '';
        
        this.place = place;
        
        this.services = [];   
        
        const expresion_hub = /^Hub/g;
        const expresion_sub = /^Sub/g;
        
        if(place_type.match(expresion_hub) || place_type.match(expresion_sub))
        {
            this.services.push({
                'name': `10 Yard Dumpsters in ${this.place}`,
                'url': 'h/'+place_name+'/10-yard-dumpster',
                'code': 'static'
            });
    
            this.services.push({
                'name': `20 Yard Dumpsters in ${this.place}`,
                'url': 'h/'+place_name+'/20-yard-dumpster',
                'code': 'static'
            });

            this.services.push({
                'name': `Roll Off Dumpsters in ${this.place}`,
                'url': 'h/'+place_name+'/roll-off-dumpsters',
                'code': 'static'
            });
    
            this.services.push({
                'name': `Construction Dumpsters in ${this.place}`,
                'url': 'h/'+place_name+'/construction-dumpster',
                'code': 'static'
            });
        }  
        
        this.props.services.forEach(service => {    
            const service_data =
            {
                name: `${service.title} in ${this.place}`,
                url: service.url
            }

            this.services.push(service_data);
        })

        this.ulclass = code !== undefined ? code : '';
    }

    render()
    {

        const reorderArray = (arr) => {
            const reordered = [];
            const midIndex = Math.ceil(arr.length / 2);
            
            for (let i = 0; i < midIndex; i++) {
                reordered.push(arr[i]);
                
                if (i + midIndex < arr.length) {
                    reordered.push(arr[i + midIndex]);
                }
            }
            
            return reordered;
        };

        return (
            <div className="related-services">
                <h2>Related Searches in {this.place}</h2>
                <article className="provider-card">
                    <div className={"items-list "+this.ulclass}>
                        {
                        reorderArray(this.services)?.map((service, index) => (
                            service && 
                            <a key={index} href={'/'+service.url} className="item">
                                <div className="item-text">                                    
                                    <h6>{service.name}</h6>
                                </div>
                            </a>
                        ))
                        }
                    </div>
                </article>
            </div>)
    }
};