import React from "react";

export default class BlockHowToGuidesDumpsters extends React.Component  
{
    
    render()
    {
        return (
            <div className="how-to-guides">
                <h2>How-To Articles</h2>
                <div className="provider-card">    
                    <div className="items-list">
                        <a href={'/blog/dumpster-rental-101'} className="item">
                            <div className="item-text">
                                <h5>GETTING STARTED</h5>
                                <h6>Dumpster rental 101 begins</h6>
                            </div>
                        </a>
                        <a href={'/blog/the-ultimate-guide-to-dumpster-sizes'} className="item">
                            <div className="item-text">
                                <h5>SIZES</h5>
                                <h6>Ask for the right dumpster size</h6>
                            </div>
                        </a>
                        <a href={'/blog/roll-off-dumpster-prices'} className="item">
                            <div className="item-text">
                                <h5>PRICES</h5>
                                <h6>Lower your cost to rent</h6>
                            </div>
                        </a>
                        <a href={'/blog/what-can-i-put-in-a-dumpster'} className="item">
                            <div className="item-text">
                                <h5>Debris</h5>
                                <h6>What can and can't go in a dumpster</h6>
                            </div>
                        </a>
                        <a href={'/blog/dumpster-rental-terms-and-conditions-a-closer-look-at-the-fine-print'} className="item">
                            <div className="item-text">
                                <h5>RENTAL TERMS</h5>
                                <h6>Understand what you're agreeing to</h6>
                            </div>
                        </a>
                        <a href={'/blog/hiring-the-right-dumpster-service-everything-you-need-to-know'} className="item">
                            <div className="item-text">
                                <h5>SERVICE</h5>
                                <h6>Comparing dumpster services</h6>
                            </div>
                        </a>
                        <a href={'/blog/rent-a-dumpster-or-hire-a-junk-removal-company'} className="item">
                            <div className="item-text">
                                <h5>OPTIONS</h5>
                                <h6>Rent a dumpster or hire junk removal</h6>
                            </div>
                        </a> 
                    </div>                    
                </div>
            </div>
        )
    }
};