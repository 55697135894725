import React from "react";
import { formatBookingJson, manualGoogleEvents } from "../helpers/helpers";
import CallToActionDescription from "./call-to-action-description";

export default class ProfilesCardsDumpster extends React.Component {



    constructor(props) {
        super(props)

        this.profiles = []
        if (this.props.profiles.length > 0) {
            this.profiles = this.props.profiles
        }
        this.profilesById = this.props.cards
        this.cardsPerPage = 6;
        this.defaultCards = 15;
        // Sub01 to Sub09 limit to 12 cards per page
        if(this.props.place.ht?.includes('Sub')){
			this.defaultCards = 12;
		}
        this.page = 0;

        this.code = this.props.code;
        this.place = this.props.place;
        this.onlineBookingPros = this.props.online_booking_pros;
        this.commercialProjectType = "509"

    }

    addQuote = (id) => {
        if(this.onlineBookingPros.includes(+id) && this.code !== 'comercial-trash-service' && this.code !== 'junk-removal'){
            return(
                <CallToActionDescription 
                    detail="Online pricing"
                    startVisible={false}
                    proid={id}
                    class="get-a-quote view-prices"
                    ondeffCode='viewPricesForm'
                    btnLabel='Book online'
                    callBackAfterSubmit={this.handleCallBackAfterSubmit}
                />
            )
        } else {
            if(this.code === 'comercial-trash-service'){
                return (
                    <CallToActionDescription 
                        detail="Request pricing & availability"
                        startVisible={false}
                        proid={id}
                        initialFormData={[{ key: "pro_id", value: id }, { key: "project_type", value: this.commercialProjectType }]}
                        ondeffCode='commercialWasteQuoteRequest'
                        class="get-a-quote"
                    />
                )
            }
            else if(this.code === 'junk-removal'){
                return (
                    <CallToActionDescription 
                        detail="Request pricing & availability"
                        startVisible={false}
                        proid={id}
                        ondeffCode='junkRemovalQuoteRequest'
                        class="get-a-quote"
                    />
                ) 
            } else{
                return (
                    <CallToActionDescription 
                        detail="Request a quote"
                        startVisible={false}
                        proid={id}
                        ondeffCode='priceAndAvailabilityForm'
                        class="get-a-quote view-prices-and-availability"
                        btnLabel='Get pricing & availability'
                    />
                )  
            }
        }
    }

    handleRedirect = (data) => {
        const url = data.split(/data-url="([^"]+)/)
        if(url?.length === 3){
            window.location.href = url[1];
        }
    }

    handleCallBackAfterSubmit = (formData) => {
        const formattedData = formatBookingJson(formData);
        if(formattedData?.pro_id && this.profilesById[formattedData.pro_id]) {
            manualGoogleEvents('manualFormSubmition');
            this.handleRedirect(this.profilesById[formattedData.pro_id])
        }
    }

    render() {
        const handleClick = async (e) => {
            e.preventDefault();
            var profilesByIdAux = this.profilesById
            const localCode = this.code === 'dumpster' ? 'dumpster-rental' : this.code === 'comercial-trash-service' ? 'commercial-waste-management' : this.code
            this.page = this.page + 1;
            return await Promise.all(this.profiles.slice(this.defaultCards + ((this.page - 1)*this.cardsPerPage), (this.defaultCards + this.page*this.cardsPerPage)).map((profile, index) => {
                return fetch('/pros/' + localCode + '/' + profile.id + '.html')
                .then(res => {
                    return res.text();
                })
                .then(res => {
                    if (res.startsWith("<div class=\"flex-head\"")) {
                        res = res.replace(/\[place\]/g, this.place.name);
                        profilesByIdAux[profile.id] = res;
                        return profile
                    }
                    else {
                        throw new Error('Invalid card');
                    }
                    
                }).catch((error) => {
                    profile.remove = 1
                    return profile
                });
            })).then(() => {
                this.setState({
                    profilesById: profilesByIdAux
                })
                return this.forceUpdate()
            })            
        }

        return (
            <div className="items-grid prof dumpster">
                <div className="container">
                    <div id="profiles-cards-list" className="row">
                        {
                            this.profiles.slice(0, (this.defaultCards + this.page*this.cardsPerPage)).filter(item => item.remove != 1).map((profile, index) => {
                               const pro = profile
                               if(this.profilesById[pro.id]){
                                    return (
                                        <div data-proid={pro.id} key={index} className="card-pro-view">
                                            <div data-id={pro.id} className="item" >
                                                <div dangerouslySetInnerHTML={{__html: this.profilesById[pro.id]}} onClick={() => this.handleRedirect(this.profilesById[pro.id])}>
                                                
                                                </div>
                                                {this.addQuote(pro.id)} 
                                            </div>
                                        </div>
                                    )
                                }else{
                                    profile.remove = 1
                                    return <></>
                                }  
                            })
                        }
                    </div>
                    {this.profiles?.length > (this.defaultCards + this.page*this.cardsPerPage) &&
                    <div className="load-more-container">
                        <button onClick={handleClick} className="load-more-btn">Show More</button>
                    </div>
                   }
                </div>
            </div>
        )
    }
}