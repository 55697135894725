import React, { useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';

const CloseModalConfirmation = (props) => {
    const [isDiscared, setIsDiscarded] = useState(false);

    const styles = {
        container: {
            marginTop: 15,
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        radioText: {
            fontSize: 20,
            color: '#212529',
            fontWeight: '400',
            verticalAlign: 'top'
        },
        selectModalTitle: {
            color: "#333",
            fontSize: '2.5rem',
            fontWeight: 700
        },
        selectModalBtn: {
            backgroundColor: '#f58220',
            border: 'none',
            borderRadius: '4px',
            boxShadow: '0 1px 1px rgba(0, 0, 0, .14)',
            color: '#fefefe',
            display: 'inline-block',
            fontSize: '1.8rem',
            fontWeight: 400,
            margin: '0 5px',
            padding: '15px 20px',
            textTransform: 'inherit',
            minWidth: '64px',
        },
        discardModalBtn: {
            backgroundColor: '#fefefe',
            border: '2px solid rgba(0, 0, 0, 0.9)',
            borderRadius: '4px',
            // boxShadow: '0 1px 1px rgba(0, 0, 0, .14)',
            color: 'rgba(0, 0, 0, 0.9)',
            display: 'inline-block',
            fontSize: '1.8rem',
            fontWeight: 400,
            margin: '0 5px',
            padding: '15px 20px',
            textTransform: 'inherit',
            minWidth: '64px',
        },
        marginTop: {
            marginTop: '25px',
            marginBottom: '50px'
        },
        centered: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        loaderColor: {
            color: '#f58220'
        }
    };

    const nextStep = () => {
        props.handleContinueForm()
    }

    const handleDiscard = () => {
        setIsDiscarded(true);
        props.handleCloseModal();
    }

    const handleClose = () => {
        if(isDiscared) {
            props.handleCloseModal();
        } else {
            props.handleContinueForm();
        }
    }

    return (
        <>
            <div className="modal-header">
                <button type="button" className="close closermodal" onClick={handleClose} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div style={{ padding: '0px 20px' }}>
                {!isDiscared ?
                <>
                    <div>
                        <h1 className='center' style={styles.selectModalTitle}>You're almost done</h1>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                        <div style={styles.container}>
                            <label htmlFor="dumpster" style={styles.radioText}>Are you sure you want to leave now and lose your progress?</label>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div 
                        className="modal-footer footer-modal-btn" 
                        style={styles.marginTop}
                    >
                        <button style={styles.discardModalBtn} onClick={handleDiscard}>Discard</button>
                        <button style={styles.selectModalBtn} onClick={nextStep}>Continue request</button>
                    </div>
                </>
                :
                <>
                    <div>
                        <h1 className='center' style={styles.selectModalTitle}>Redirecting to pricing...</h1>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                        <div style={styles.container}>
                            <label htmlFor="dumpster" style={styles.radioText}>For additional quotes, request pricing & availability.</label>
                        </div>
                        <br />
                        <br />
                        <div style={styles.centered}>
                            <CircularProgress style={styles.loaderColor}/>
                        </div>
                    </div>
                </>
                }
            </div>
        </>
    )
}

export default CloseModalConfirmation;