import React from 'react';
import Breadcrumb from './breadcrumb';

export default class Banner extends React.Component {

    constructor(props) {
        super(props);

        if (this.props.state !== undefined) {
            this.place =
            {
                name: undefined,
                state:
                {
                    name: this.props.state.name,
                    av: this.props.state.av
                }
            }
        } else {
            this.place = this.props.place;
        }
        this.code = this.props.code;
        this.codes = this.props.codes;
        this.env = this.props.env;
    }

    addBreadcrumb = () => {
        if (this.props.state !== undefined || this.props.breadcrumb) {
            return (
                <Breadcrumb
                    code={this.code}
                    place={this.place}
                    codes={this.codes}
                    url={this.props.url} 
                    class='breadcrumb-banner'/>
            )
        }
    }

    render() {
        return (
            <div className="banner">
                <div className="container">
                    {this.addBreadcrumb()}
                    <section className="row">
                        <div className="banner-title">
                            <h1>{this.props.title}</h1>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}