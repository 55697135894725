import Modal from 'react-modal';
import React, { useState, lazy, Suspense, forwardRef, useImperativeHandle, useRef } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import CloseModalConfirmation from './closeModalConfirmation';

const OnDeffImplementation = lazy(() => import('./on-deff-implementation'))

const ServiceModalSelect = forwardRef((props, ref) => {
    const proId = props.proid;
    const commercialProjectType = "509";
    const [selectedValue, setSelectedValue] = useState("dumpster");
    const [loading, setLoading] = useState(false);
    const [selectService, setSelectService] = useState(false);
    const [ondeffCode, setOndeffCode] = useState('priceAndAvailabilityForm');
    const [title, setTitle] = useState(props.title)
    const [closeModal, setCloseModal] = useState(false);
    const childRef = useRef();
    const availableServices = props.availableServices ? props.availableServices : {}
    
    let initialValuePoolLimit = 0;
    if (typeof window !== 'undefined') {
        const params = new URLSearchParams(window.location.search)
        if (params.get("test") == "true") {
            initialValuePoolLimit = 1;
        }
    }
    const [limitPoolSize, setPoolTest] = useState(initialValuePoolLimit)
    const defaultData = proId ? [{ key: 'pro_id', value: proId }, { key: 'limit_poolsize', value: limitPoolSize }] : [{ key: 'limit_poolsize', value: limitPoolSize }];
    const [iniinitialData, setInitialData] = useState(defaultData);
    

    const changeSelectedValue = (e) => {
        setSelectedValue(e.target.value);
    }

    const [showModal, setShowModal] = useState(false)

    const changeShowModal = () => {
        if(selectService) {
            setShowModal(!showModal)
            setSelectedValue('dumpster');
        } else {
            if(!showModal) {
                setShowModal(true);
                setTitle(props.title);
                setSelectService(true);
                setSelectedValue('dumpster');
            } else {
                const submitMessageElement = document.querySelector('.submitMessageGA');
                if(!submitMessageElement){
                    setCloseModal(true);
                } else {
                    setShowModal(false);
                }
            }
        }
    }

    const handleCloseModal = () => {
        if(ondeffCode === 'priceAndAvailabilityForm') {
            if (childRef?.current) {
                const formValues = childRef.current?.getCurrentValues();
                if(formValues?.odf_place_id && formValues?.wasteType) {
                    setTimeout(() => {
                        closeForm();
                        window.location.href = `/book?a=${formValues.odf_place_id}&w=${formValues.wasteType}`
                    }, 5000);
                } else {
                    closeForm();
                }
            } else {
                closeForm();
            }
        } else {
            closeForm();
        }
    }

    const closeForm = () => {
        setShowModal(false);
        setCloseModal(false);
    }

    const handleContinueForm = () => {
        setCloseModal(false);
    }

    const [modalStyles, setModalStyles] = useState(
        {
            content:
            {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                borderRadius: '10px',
                border: 'none',
                backgroundColor: '#FFFFFF',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                padding: '0px',
                width: '600px',
            }
        }
    )

    const styles = {
        container: {
            marginTop: 15,
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        radioText: {
            fontSize: 20,
            color: '#212529',
            fontWeight: '400',
            verticalAlign: 'top'
        },
        radioCircle: {
            height: 20,
            width: 20,
            marginRight: 15,
            borderRadius: 100,
            borderWidth: 2,
            borderColor: 'red',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'red',
            color: "red"
        },
        selectModalTitle: {
            color: "#333",
            fontSize: '2.5rem',
            fontWeight: 700
        },
        selectModalBtn: {
            backgroundColor: '#f58220',
            border: 'none',
            borderRadius: '4px',
            boxShadow: '0 1px 1px rgba(0, 0, 0, .14)',
            color: '#fefefe',
            display: 'inline-block',
            fontSize: '1.8rem',
            fontWeight: 400,
            margin: '0 5px',
            padding: '15px 20px',
            textTransform: 'inherit',
            minWidth: '64px',
        },
        marginTop: {
            marginTop: '25px',
            marginBottom: '50px'
        }
    };

    const nextStep = () => {
        setSelectService(false)
        if (selectedValue == "dumpster") {
            setOndeffCode('priceAndAvailabilityForm')
            setInitialData(defaultData)
        }
        else if (selectedValue == "junk-removal") {
            setOndeffCode('junkRemovalQuoteRequest')
            setInitialData(defaultData)
        } else {
            setInitialData([...defaultData, { key: "project_type", value: commercialProjectType }])
            setOndeffCode('commercialWasteQuoteRequest')
        }
    }

    const handleCallBackAfterSubmit = (formData) => {
        if (props.callBackAfterSubmit) {
            props.callBackAfterSubmit(formData)
        }
        else if (ondeffCode === 'priceAndAvailabilityForm' || ondeffCode === 'availabilityForm') {
            setTitle(`We're finding available pros now`);
        }
    }

    const handleCallBackErrorAfterSubmit = (formData) => {
        if (props.callBackErrorAfterSubmit) {
            props.callBackErrorAfterSubmit(formData)
        }
    }

    useImperativeHandle(ref, () => ({
        triggerChangeShowModal: () => changeShowModal()
    }));
    return (
        <>
            <div className="">
                <button style={{ cursor: 'pointer' }} className="profile-button-form" onClick={changeShowModal}>Get pricing & availability</button>
            </div>
            <Modal
                id={1}
                isOpen={showModal}
                style={modalStyles}
                contentLabel="Get a Quote!"
                shouldCloseOnOverlayClick={false}
                preventScroll={true}
                ariaHideApp={false}
            >
                { selectService && 
                <>
                    <div className="modal-header">
                        <button type="button" className="close closermodal" onClick={changeShowModal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div style={{ padding: '0px 20px' }}>
                        <div>
                            <h1 className='center' style={styles.selectModalTitle}>What service do you need?</h1>
                        </div>
                        <div onChange={changeSelectedValue} style={{ marginTop: '30px' }}>
                            { availableServices['Dumpster Rental'] &&
                            <div style={styles.container}>
                                <input id="dumpster" style={styles.radioCircle} type="radio" value="dumpster" checked={selectedValue === "dumpster"} />
                                <label htmlFor="dumpster" style={styles.radioText}>Dumpster rental</label>
                            </div>
                            }
                            { availableServices['Junk Removal'] &&
                            <div style={styles.container}>
                                <input id="junk-removal" style={styles.radioCircle} type="radio" value="junk-removal" checked={selectedValue === "junk-removal"} />
                                <label htmlFor="junk-removal" style={styles.radioText}>Junk removal</label>
                            </div>
                            }
                            { availableServices['Commercial Trash Service'] &&
                            <div style={styles.container}>
                                <input id="commercial-trash-service" style={styles.radioCircle} type="radio" value="commercial-trash-service" checked={selectedValue === "commercial-trash-service"} />
                                <label htmlFor="commercial-trash-service" style={styles.radioText}>Commercial trash service</label>
                            </div>
                            }
                        </div>
                        <div className="modal-footer" style={styles.marginTop}>
                            <button style={styles.selectModalBtn} onClick={nextStep}>Next</button>
                        </div>
                    </div>
                </>}

                { !selectService && 
                <>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <>
                        <div className={closeModal ? 'display-none-form' : (!title ? "modal-header" : "modal-header review-quote-step-modal")}>
                            {title && 
                            <h2>{title}</h2>
                            }
                            <button type="button" className="close closermodal" onClick={changeShowModal} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div id="quote-steps-modal-library" className={closeModal ? 'display-none-form' : ''}>
                            <Suspense fallback={<h1>Loading…</h1>}>
                                <OnDeffImplementation
                                    initialData={iniinitialData}
                                    formCode={ondeffCode}
                                    loadingFunction={setLoading}
                                    handleCallBackAfterSubmit={handleCallBackAfterSubmit}
                                    handleCallBackErrorAfterSubmit={handleCallBackErrorAfterSubmit}
                                    ref={childRef}
                                >
                                </OnDeffImplementation>
                            </Suspense>
                        </div>
                    </>
                    {closeModal &&
                    <CloseModalConfirmation
                        handleContinueForm={handleContinueForm}
                        handleCloseModal={handleCloseModal}
                    />
                    }
                </>
                }
            </Modal>
        </>
    )
});

export default ServiceModalSelect;